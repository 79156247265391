import React from "react";
import { Donut } from "..";
import { extractEntityBreakdown } from "./helpers/extractData";
import { transformEntityBreakdown } from "./helpers/transformData";
import { WorkBook } from "xlsx/types";
import { getEntityBreakdownSheet } from "./helpers/getSheets";

export const FromWorkBook = (workbook: WorkBook) => {
  const sheet = getEntityBreakdownSheet(workbook);
  const sheetData = extractEntityBreakdown(sheet);
  const data = transformEntityBreakdown(sheetData);

  return <Donut heading="Entity Breakdown (% NAV)" data={data}></Donut>;
};