import * as React from "react";
import styled from "styled-components";

import { paleGrey, red } from "../../../utils/colors";
import { XLSXCell } from "../../../utils/xlsx/getDataRange";

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;

  td {
    padding: 2px 5px;
  }
`;

const Body = styled.tbody`
  width: 100%;
  height: 100%;
`;

const Row = styled.tr<{
  blue?: boolean;
  bold?: boolean;
  center?: boolean;
}>`
  background-color: ${(props) => (props.blue ? red : paleGrey)};
  color: ${(props) => (props.blue ? 'white' : 'inherit')};
  border-bottom: solid 1px ${(props) => (props.blue ? "white" : '#bfcfd8')};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 7.5pt;
  td {
    color: ${(props) => (props.blue ? 'white' : 'inherit')};
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  }
`;

export type SharesTable = {
  headers: string[];
  primarys: string[];
  values: (string)[][];
};

export type SharesTablePointer = {
  headers: (string | XLSXCell)[];
  primarys: string[];
  values: SharesTableRow[] | (string)[][];
};

export type SharesTableRow = {
  cellRef: (string | XLSXCell)[] | string
  sheetIndex?: number,
};

export type SharesProps = {
  shares: SharesTable;
};

const Shares = ({ shares }: SharesProps) => (
  <Table>
    <Body>
      <Row blue={true} bold={true}>
        {shares.headers.map((header: string, index: number) => (
          <td align={index === 0 ? 'left' : 'center'} key={index}>{header}</td>
        ))}
      </Row>
      {shares.values.map((values: string[], rowIndex: number) => (
        <Row key={rowIndex}>
          <td>{shares.primarys[rowIndex]}</td>
          {values.map((value: string, index: number) => {
            let isMoney = shares.primarys[rowIndex].toLowerCase() === 'NAV per Share'.toLowerCase();
            let valueToShow = value;
            if (isMoney) {
              valueToShow = value.split(' ').join('');
            }
            return (
              <td align="center" key={index}
                colSpan={values.length === 1 ? 99 : 1}
              >{valueToShow}</td>
            )
          })}
        </Row>
      ))}
    </Body>
  </Table>
);

export default Shares;
