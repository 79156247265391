import React from "react";
import Spinner from '../../spinner';
import { Row, Col } from 'react-bootstrap';
import Paragraph from "../../factsheets/Paragraph/Paragraph";
import { decideData } from "../../factsheets/FundObjectives/helpers/decideData";
import DailyPricing from "./DailyPricing";
import styled from 'styled-components';
import { red } from '../../../utils/colors';

const ButtonLink = styled.button`
    background: none;
    color: ${red};
    border: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &:hover {
        text-decoration: underline;
    }
`;

export default class FundInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            template: props.template
        }
    }

    render() {
        const { template, loading } = this.state;
        if (loading) {
            return (<Spinner />);
        }

        return (
            <>
                <Row classes="mb-1">
                    <Col>
                            {decideData(template).map((item, index) => {
                                return index > 0
                                    ? <Paragraph paragraph={item} key={index}></Paragraph>
                                    : (<React.Fragment key={index}>
                                        <Paragraph paragraph={item}></Paragraph>
                                        <span>
                                            Key Investor Information can be found <ButtonLink onClick={this.props.selectLiterature}>here</ButtonLink>.
                                        </span>
                                    </React.Fragment>)
                            })}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DailyPricing template={template}></DailyPricing>
                    </Col>
                </Row>
            </>
        );
    }
}