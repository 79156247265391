import React from 'react';
import styled from "styled-components";
import { FactSheet } from '../../FactSheet';
import { Heading } from '../factsheets/Paragraph/Paragraph';

const AwardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin-right: 10px;
`;

const BarclayHedge = styled.a`
    background: url(https://portal.barclayhedge.com/images/awards/2011-240-X-400-topten.jpg);
    width: 100%;
    background-size: 100%;
    height: 334px;
    background-repeat-y: no-repeat;
    margin: 5px 0 0 0;
    &:hover {
        text-decoration: none;
    }
`;

const BarclayHedgeText = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    text-align: center;
    font-size: 9px;
    color: #8f5d32;    
    position: relative;
    top: 114px;
    > * {
        font-family: Georgia, "Times New Roman", Times, serif;
    }
`;

const ImgAward = styled.img`
    margin: 0;
    width: 100%;
`;

const NextGen = () => (
    <BarclayHedge href="https://www.barclayhedge.com/?btg_trk=award" target="blank">
        <BarclayHedgeText>
            <strong>Next Generation Gl Bond Fund QAEUR</strong>
            <span>Fixed Income - Long-Only Credit</span>
            <strong>For the year of 2020</strong>
            <i>Ranked by Net Return</i>
        </BarclayHedgeText>
    </BarclayHedge>
)

const NFA = () => (
    <BarclayHedge href="https://www.barclayhedge.com/?btg_trk=award" target="blank">
        <BarclayHedgeText>
            <strong>NFA Global Bond QDUSD Dist</strong>
            <span>Fixed Income - Long-Only Credit</span>
            <strong>For the year of 2020</strong>
            <i>Ranked by Net Return</i>
        </BarclayHedgeText>
    </BarclayHedge>
)

function FundAwards({ template }) {
    let awards = null;
    switch (template) {
        case FactSheet.ss_nfa_ucits:
            awards = NFA();
            break;
        case FactSheet.ss_next_gen_ucits:
            awards = NextGen();
            break;
    }
    if (!awards) {
        return <></>;
    }


    return (
        <AwardsContainer>
            <Heading>Awards</Heading>
            {awards}
        </AwardsContainer>
    );
}

export default FundAwards;