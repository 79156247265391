import * as React from "react";
import styled from "styled-components";
import { paleGrey, red } from "../../../utils/colors";

const Table = styled.table`
  margin-bottom: 1mm;
  width: 100%;
  & tbody tr {
    width: 100%;
    width: 100%;
  }
  & th {
    padding: 4px;
  }
`;

const TopRow = styled.tr`
  background-color: ${red};
  th {
    color: white;
  }
`;

const BottomRow = styled.tr`
  border-bottom: solid 1px #bfcfd8;
  background: ${paleGrey};
`;

const NormalTableHead = styled.th`
  font-weight: normal;
`;

export type CumulativePerformanceProps = {
  headers: string[];
  primaries: string[];
  values: string[][];
};

export default ({ headers, primaries, values }: CumulativePerformanceProps) => {
  return (
    <Table>
      <tbody>
        <TopRow>
          {headers.map((header: string, index: number) => (
            <th key={index}>{header}</th>
          ))}
        </TopRow>

        {values.map((row: string[], index: number) => (
          <BottomRow key={index}>
            <NormalTableHead>{primaries[index]}</NormalTableHead>
            {row.map((value: string, innerIndex: number) => (
              <NormalTableHead key={innerIndex}>{value}</NormalTableHead>
            ))}
          </BottomRow>
        ))}
      </tbody>
    </Table>
  );
};
