import React from "react";
import Loader from 'react-loader-spinner';
import { chartNavyBlue } from '../utils/colors';
import styled from "styled-components";


const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
`;

export default ({ message }) => {
    message = message || 'Loading latest Fund data...'
    return (
        <LoaderContainer>
            <Loader
                type="Oval"
                color={chartNavyBlue}
                height={54}
                width={54} />
            <span className="mt-2">{message}</span>
        </LoaderContainer>);
}