import React from "react";
import { createWorkbookFromBase64 } from '../../../utils/xlsx'
import { getDailyPricingSheet, extractDailyPrices, transformDailyPrices, getDailyPricingUrl } from '../../../utils/daily-pricing-helpers'
import Spinner from '../../spinner';
import styled from "styled-components";
import { veryLightGrey, chartLightBlue, veryDarkBlue } from '../../../utils/colors';
import { Heading } from '../../factsheets/Paragraph/Paragraph';
import * as moment from 'moment';
import { FactSheet } from '../../../FactSheet';
import { getCell } from "../../../utils/xlsx/getDataRange";

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DailyPriceCard = styled.div`
    width: 200px;
    min-height: 85px;
    padding: 10px;
    margin: 5px;
    background: ${veryLightGrey};

    @media(max-width: 800px) {
        margin: auto;
        margin-bottom: 10px;
    }
`;

const DailyPrice = styled.div`
    font-size: 20pt;
    text-align: right;
    color: ${veryDarkBlue};
`;

const DailyPriceName = styled.div`
    font-size: 11pt;
    color: ${veryDarkBlue};
    text-align: right;
    white-space: pre;
`;

const SourceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const parseDate = (workbook) => {
    const cellRef = {c: 1, r: 2};
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const dateValue = getCell(cellRef, sheet)?.w;
    if (dateValue) {
        let mom = moment(dateValue, 'DD.MM.YYYY');
        return mom;
    }

    return null;
}

const source = (mom, source) => (
    <SourceWrapper>
        <i className="mt-2">Data as at: {mom.format('DD/MM/yyyy')}</i>
        <i className="mt-1">Source: {source}</i>
    </SourceWrapper>
);

export default class DailyPricing extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
            fileBase64: null,
            date: null
        }
    }

    componentDidMount() {
        if (this.props.template === FactSheet.ss_nfa_ucits
            || this.props.template === FactSheet.ss_next_gen_ucits) {

            fetch(`${process.env.GATSBY_DAILY_PRICING_URL}&template=${this.props.template}`)
                .then(async res => {
                    var result = await res.json();
                    var base64Str = result.data;
                    this.setState({
                        fileBase64: base64Str
                    });
                })
                .finally(() => {
                    this.setState({ loading: false });
                })

        } else {
            const template = this.props.template;
            let url = getDailyPricingUrl(template, url);
            this.setState({
                externalUrl: url,
                loading: false
            })
        }
    }

    render() {
        const { loading, fileBase64, externalUrl } = this.state;

        if (loading) {
            return (<Spinner message="Loading Daily Prices..." />);
        }

        const sourceText = 'Universal Investment GmbH';
        const workbook = createWorkbookFromBase64(fileBase64);
        const date = parseDate(workbook);

        try {
            return (
                <div>
                    <Heading>Daily Prices</Heading>
                    {fileBase64 ? FromWorkBook(workbook, this.props.template) : StaticUrl(externalUrl)}
                    {date ? source(date, sourceText) : ''}
                </div>
            );
        } catch (e) {
            console.error(e);
            return (
                <div>
                    <Heading>Daily Prices</Heading>
                    <i>Could not find the latest Daily Pricing information.</i>
                </div>
            );
        }
    }
}

const FromWorkBook = (workbook, template) => {
    let sheet = getDailyPricingSheet(workbook);
    let { classes, values } = extractDailyPrices(sheet, template);
    let dailyPrices = transformDailyPrices(classes, values, template);

    return (
        <React.Fragment>
            <Boxes>
                {dailyPrices.map((group, index) => (
                    <DailyPriceCard key={index}>
                        <DailyPrice>
                            {group[1]}
                        </DailyPrice>
                        <DailyPriceName>
                            {group[0]}
                        </DailyPriceName>
                    </DailyPriceCard>
                ))}
            </Boxes>

        </React.Fragment>
    );
}
const StaticUrl = (externalUrl) => {
    return (
        <React.Fragment>
            Daily Prices can be found at: <a target="blank" href={externalUrl}>{externalUrl}</a>
        </React.Fragment>
    );
}