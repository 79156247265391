import React from "react";
import { decideData } from "./helpers/decideData";
import Shares from "./Shares";
import { FactSheet } from "../../../FactSheet";
import { getSheet } from "./helpers/getSheet";
import { WorkBook } from "xlsx/types";
import { transformData } from "./helpers/transformData";

export function FromWorkBook(workbook: WorkBook, template: FactSheet) {
  const sheet = getSheet(workbook);
  const info = decideData(template);
  const data = transformData(info, sheet);
  return (<Shares shares={data} />);
}

