import * as React from "react";
import styled from "styled-components";

import { paleGrey, red } from "../../../utils/colors";

const AssetClassGridWithGraph = styled.div`
  display: grid;
  flex: 1;
  grid-template-areas:
    "topLeft right"
    "middleLeft right"
    "bottomLeft right";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1.1fr;
  > *:nth-child(1) {
  padding-bottom: 1mm;
  }
  > *:nth-child(2), *:nth-child(3) {
    padding-top: 1mm;
    padding-left:1mm;
  }
`;

const AssetClassGridWithoutGraph = styled.div`
  display: grid;
  flex: 1;
  grid-template-areas:
    "left topRight"
    "left bottomRight";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  > *:nth-child(1) {
    padding-bottom: 1mm;
  }
  > *:nth-child(2), *:nth-child(3) {
    padding-top: 1mm;
    padding-left:1mm;
  }
`;

const VerticalAssetClassGrid = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  > table {
    margin-bottom: 1mm;
  }
`;

export const AssetClassGrid = ({
  withGraph,
  children,
}: {
  withGraph: boolean;
  children: React.ReactNode;
}) =>
  withGraph ? (
    <AssetClassGridWithGraph>{children}</AssetClassGridWithGraph>
  ) : (
      <AssetClassGridWithoutGraph>{children}</AssetClassGridWithoutGraph>
    );

export const VerticalAssetClasses = ({ children }: { children: React.ReactNode; }) =>
  (<VerticalAssetClassGrid>{children}</VerticalAssetClassGrid>);

const Table = styled.table`
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 7.5pt;
`;

const Body = styled.tbody`
  & tr {
    &:first-child {
      background-color: ${red};
      * {
        color: white;
        font-size: 1em;
      }
    }
    &:not(:first-child) {
      background-color: ${paleGrey};
      border-bottom: 1px solid rgb(191, 207, 216);
    }
  }
`;

const Row = styled.tr<{ center: boolean }>`
  padding: 4px;
  min-height: 20px;
  & td {
    &:nth-child(2n) {
      text-align: center;
    }
  }
  th {
    text-align: ${props => props.center ? 'center' : 'left'};
    max-height: 20px;
    &:nth-child(2n) {
      text-align: center;
    }
  }
`;

export interface AssetClassRow {
  key: string;
  assetClass?: string;
  percentage?: number;
}

export interface AssetClassData {
  headings: string[];
  rows: AssetClassRow[];
}
export interface AssetClassProps extends AssetClassData {
  columns: number;
}

export default ({ headings, rows, columns }: AssetClassProps) => {
  return (
    <Table>
      <Body>
        <Row center={headings.length === 1}>
          {Heading(headings)}
        </Row>
        {columns === 1 ? OneColumnRow(rows) : TwoColumnRow(rows)}
      </Body>
    </Table>
  )
};

const Heading = (headings: string[]) => {
  const headingsOnce = headings.map((heading: string, index: number) => (
    <th key={index} colSpan={headings.length === 1 ? 99 : 1}>{heading}</th>
  ));

  return headingsOnce;
}

const OneColumnRow = (rows: AssetClassRow[]) => {
  return (rows.map((row: AssetClassRow, index: number) => (
    <Row center={false} key={index}>
      <td dangerouslySetInnerHTML={{ __html: row.key }} />
      <td>{row.assetClass}</td>
    </Row>
  )))
};

const TwoColumnRow = (rows: AssetClassRow[]) => {
  let items = []
  const numRows = rows.length / 2;
  for (let index = 0; index < numRows; index++) {
    let firstColumn = rows[index];
    let secondColumn = rows[index + numRows];
    items.push(
      <Row center={false} key={index}>
        <td dangerouslySetInnerHTML={{ __html: firstColumn.key }} />
        <td>{firstColumn.assetClass}</td>
        <td dangerouslySetInnerHTML={{ __html: secondColumn.key }} />
        <td>{secondColumn.assetClass}</td>
      </Row>)
  }

  // return result;
  return (items)
};