import { SharesTable } from "../Shares";
import { XLSXCell, getCell } from "../../../../utils/xlsx/getDataRange";
import { WorkSheet } from "xlsx/types";

export const transformData = (tablePointer: any, sheets: WorkSheet[] | undefined): SharesTable => {
    let headers = tablePointer.headers.map(getValue(!!sheets ? sheets[0] : undefined));
    let values: string[][] = tablePointer.values.map((row: any) => {
        if (typeof row === 'string') {
            return [row];
        }
        if (row.length){
            return row.map(getValue(!!sheets ? sheets[0] : undefined));
        }
        return row.cellRef.map(getValue(!!sheets ? sheets[!!row.sheetIndex ? row.sheetIndex : 0] : undefined));
    });
    return {
        headers: headers,
        values: values,
        primarys: tablePointer.primarys
    }
}

function getValue(sheet: any): (value: any, index: number, array: any[]) => string {
    return m => {
        if (typeof m === 'string') {
            return m;
        }
        let cellRef = m as XLSXCell;
        const cell = getCell(cellRef, sheet);
        return cell.w;
    };
}
