import styled from "styled-components";

import { paleGrey } from "../../utils/colors";

const DefaultBox = `
  height: auto;
  background-color: ${paleGrey};
  color: black;
  white-space: pre-wrap;
  margin-bottom: 1mm;
`;

export const Box = styled.div`
  ${DefaultBox}
`;
