import { SheetData } from "../../../../utils/xlsx/getDataRange";
import { BarChartData } from "../VerticalBars";

export const transformSheetData = (sheetData: SheetData[][]): BarChartData[] => {
    let data: BarChartData[] = [];
    const length = sheetData[0].length;
    for (let i = 0; i < length; i++) {
        const pair: [SheetData, SheetData] = [sheetData[0][i], sheetData[1][i]];
        let value: number = 0;
        if (!isNaN(+pair[1].v)) {
            value = +pair[1].v * 100;
        }
        if (value > 0) {
            data.push({
                name: pair[0].w, percentage: value
            });
        }
    }

    return data;
}