import { getDataRange } from './xlsx';
import { FactSheet } from '../FactSheet';

export const getDailyPricingSheet = (workbook) => {
    return workbook.Sheets[workbook.SheetNames[0]];
}

export function getDailyPricingUrl(template) {
    let url;
    switch (template) {
        default:
            url = '';
    }
    return url;
}

export const extractDailyPrices = (sheet, template) => {
    let { classColumnIndex, numPrices, valueColumnIndex, startIndex } = getDailyPriceMetaData(template);
    const classRange = {
        s: {
            c: classColumnIndex,
            r: startIndex
        },
        e: {
            c: classColumnIndex,
            r: numPrices + (startIndex - 1)
        },
    };
    const valueRange = {
        s: {
            c: valueColumnIndex,
            r: startIndex
        },
        e: {
            c: valueColumnIndex,
            r: numPrices + (startIndex - 1)
        },
    };

    return { classes: getDataRange(classRange, sheet), values: getDataRange(valueRange, sheet) };

}

export const transformDailyPrices = (classes, values, template) => {
    let mappings;
    switch (template) {
        case FactSheet.ss_next_gen_ucits:
            mappings = ss_next_gen_ucits_mappings;
            break;
        case FactSheet.ss_nfa_ucits:
            mappings = ss_nfa_ucits_mappings;
            break;
        default:
            mappings = {};
    }

    return Object.keys(mappings).map((mapping) => {
        let mappedValue = mappings[mapping];
        let index = classes.findIndex(p => p.v.trim().toLowerCase() === mappedValue.trim().toLowerCase());
        let value = values[index].w;
        let valueNum = parseFloat(value.replace(/,/g, ''));
        if (!isNaN(valueNum)) {
            value = `${getCurrencySymbol(mapping)}${valueNum.toFixed(2)}`;
        }
        return [mapping, value]
    });
}

const getCurrencySymbol = (name) => {
    if (name.includes('GBP')) {
        return '£';
    } else if (name.includes('USD')) {
        return '$';
    } else if (name.includes('EUR')) {
        return '€';
    } else if (name.includes('CHF')) {
        return 'CHF ';
    }
    return '';
}

function getDailyPriceMetaData(template) {

    switch (template) {
        case FactSheet.ss_next_gen_ucits:
            return { classColumnIndex: 1, valueColumnIndex: 7, numPrices: 5, startIndex: 10 };
        case FactSheet.ss_nfa_ucits:
            return { classColumnIndex: 1, valueColumnIndex: 7, numPrices: 5, startIndex: 5 };

        default:
            throw new Error('Unsupported template for daily pricing');
    }
}

const ss_nfa_ucits_mappings = {};
ss_nfa_ucits_mappings['Class QA EUR']='Stratton Street UCITS - NFA Gl.Bd.Fd. UI QAEUR';
ss_nfa_ucits_mappings['Class QA USD']='Stratton Street UCITS - NFA Gl.Bd.Fd. UI QAUSD';
ss_nfa_ucits_mappings['Class QD GBP']='Stratton Street UCITS - NFA Gl.Bd.Fd. UI QDGBP';
ss_nfa_ucits_mappings['Class QD EUR']='Stratton Street UCITS - NFA Gl.Bd.Fd. UI QDEUR';
ss_nfa_ucits_mappings['Class QD USD']='Stratton Street UCITS - NFA Gl.Bd.Fd. UI QDUSD';

const ss_next_gen_ucits_mappings = {};
ss_next_gen_ucits_mappings['Class QA EUR'] = 'Stratton Street UCITS-Next Gen.Gl.Bd.Fd.UI QAEUR';
ss_next_gen_ucits_mappings['Class QA USD'] = 'Stratton Street UCITS-Next Gen.Gl.Bd.Fd.UI QAUSD';
ss_next_gen_ucits_mappings['Class QD EUR'] = 'Stratton Street UCITS-Next Gen.Gl.Bd.Fd.UI QDEUR';
ss_next_gen_ucits_mappings['Class QD GBP'] = 'Stratton Street UCITS-Next Gen.Gl.Bd.Fd.UI QDGBP';
ss_next_gen_ucits_mappings['Class QD USD'] = 'Stratton Street UCITS-Next Gen.Gl.Bd.Fd.UI QDUSD';