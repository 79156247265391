export const formatMoney = (numberValue: number): string => {
  // Nine Zeroes for Billions
  const value =
    Math.abs(Number(numberValue)) >= 1.0e9
      ? (Math.abs(Number(numberValue)) / 1.0e9).toFixed(2) + "bn"
      : // Six Zeroes for Millions
      Math.abs(Number(numberValue)) >= 1.0e6
        ? (Math.abs(Number(numberValue)) / 1.0e6).toFixed(2) + "m"
        : // Three Zeroes for Thousands
        Math.abs(Number(numberValue)) >= 1.0e3
          ? (Math.abs(Number(numberValue)) / 1.0e3).toFixed(2) + "k"
          : Math.abs(Number(numberValue));
  return value.toString();
};
