import { FactSheet } from "../../../../FactSheet";
import { ParagraphModel } from "../../Paragraph/Paragraph";

export const decideData = (template: FactSheet): ParagraphModel[] => {
  switch (template) {
    case FactSheet.ss_next_gen_ucits:
      return ss_next_gen_ucits;
    case FactSheet.ss_nfa_ucits:
      return ss_nfa_ucits;
    default:
      throw Error("Template undefined or not chosen");
  }
};

const ss_next_gen_ucits: ParagraphModel[] = [
  {
    header: "Fund Objectives",
    body: [
      "The Fund is designed to provide investors with a relatively high yield whilst achieving a superior risk/reward profile.",
      "In seeking to achieve its objective, the Fund will comprise of the most attractively undervalued global bonds, as identified by the Portfolio Manager through its proprietary investment process."
    ]
  }
]

const ss_nfa_ucits: ParagraphModel[] = [
  {
    header: "Fund Objectives",

    body: [
      "The Fund seeks to sustain higher income than typical investment grade indices, by targeting undervalued investments which amplify capital appreciation while reducing downside risk.",
      "The Fund invests in investment grade bonds from those countries, and companies within those countries, which the manager considers have the greatest ability to pay their debts."
    ]
  }
]