import { SheetData } from "../../../utils/xlsx/types";
import { PieChartData } from "../../types";

export const transformEntityBreakdown = (
  sheetData: SheetData[][]
): PieChartData[] => {
  return transformSheetData(sheetData, []);
};

const transformSheetData = (
  sheetData: SheetData[][],
  labelTypes: string[]
): PieChartData[] => {
  let data: PieChartData[] = [];
  const length = sheetData[0].length;
  for (let i = 0; i < length; i++) {
    const pair: [SheetData, SheetData] = [sheetData[0][i], sheetData[1][i]];
    let value: number = 0;
    if (!isNaN(+pair[1].v)) {
      value = +pair[1].v * 100;
    }
    data.push({
      name: pair[0].w,
      value: value,
      showPercentage: !labelTypes.includes(pair[0].w.toLowerCase()),
    });
  }

  return data;
};