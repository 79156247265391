import React from "react";
import { FactSheet } from "../../../FactSheet";
import { FundInformation } from "./";
import { getStaticData } from "./helpers/getStaticData";
import { decideSheet } from "./helpers/decideSheet";
import { combineWithExcelData } from "./helpers/combineWithExcelData";
import { WorkBook } from "xlsx/types";

export default {
  component: FundInformation,
  title: "Fund Information",
  excludeStories: /.*FromWorkBook$/,
};

export function FromWorkBook(workbook: WorkBook, template: FactSheet) {
  const sheet = decideSheet(workbook);
  const info = getStaticData(template);
  const data = combineWithExcelData(sheet, info, template);
  return (<FundInformation info={data} />);
}

