import * as React from "react";
import { FactSheet } from "../../../../FactSheet";
import AssetClass, { GridArea } from "../AssetClass";
import {
  decidePortfolioStatisticsSheet,
  extractPortfolioStatistics,
  transformPortfolioStatistics,
} from "../helpers";
import { createSheet } from "../../../../utils/xlsx";
import { WorkBook } from "xlsx/types";

export function FromWorkBook(workbook: WorkBook, template: FactSheet, gridArea?: GridArea) {
  const assetClassData = DataFromWorkBook(workbook);
  return <AssetClass columns={1} {...assetClassData} gridArea={gridArea} />;
}

export function DataFromWorkBook(workbook: WorkBook) {
  const sheetIndex = decidePortfolioStatisticsSheet();
  const sheet = createSheet(workbook, sheetIndex);
  const {headers,data} = extractPortfolioStatistics(sheet);
  const assetClassData = transformPortfolioStatistics(headers, data);
  return assetClassData;
}
