import * as React from "react";
import styled from "styled-components";
import {
  ReferenceLine,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Cell,
} from "recharts";
import { GetChartColours, paleGrey, axisColour, chartIceBlue } from "../../../utils/colors";

const Wrapper = styled.div`
  background-color: ${paleGrey};
  flex: 1;
  flex-basis: 49%;
  h3 {
      background: #dd052d;
      color: white;
      font-size: 1em;
      text-align: center;
      padding: 6px;
  }
`;

const Heading = styled.h3`
  margin: 0;
  padding: 15px 0 5px 0;
  text-align: center;
  font-size: 11pt;
  font-weight: 500;
`;

export type BarChartData = {
  name: string;
  percentage: number;
};

const renderCustomizedLabel = (maxTick: number, minTick: number) => {
  return ({ x, y, value }: {
    x: number;
    y: number;
    index: number;
    value: number;
  }) => {
    const fundNavWidth = 240;
    const rightChartMargin = 35;
    const mdBreakpoint = 768;
    let chartsInRow = 2;
    let pageWidth = document.body.clientWidth - 20;
    if (pageWidth < mdBreakpoint) {
      chartsInRow = 1;
    } else {
      pageWidth -= fundNavWidth;
    }
    let chartWidth = ((pageWidth) / chartsInRow) - 100 - rightChartMargin;
    var percentageAcrossChart = ((value - minTick) / (maxTick - minTick));
    const adder = (0.89 * percentageAcrossChart * chartWidth) + 65;
    let xAnchor = adder;

    if (value > 0) {
      xAnchor += 40;
    }
    xAnchor -= 5;
    return (
      <text
        x={xAnchor}
        y={y + 20}
        fontFamily="sans-serif"
        fill={axisColour}
        textAnchor="start"> {value.toFixed(2)}%
      </text>
    )
  }
}

const VerticalBars = ({ heading, data }) => {
  let colors = GetChartColours(data.length);
  let sortedData = [...data];
  sortedData = sortedData.sort((a, b) => a.percentage - b.percentage);
  let minValue = sortedData[0].percentage;
  let maxValue = sortedData[data.length - 1].percentage;
  let difference = maxValue - minValue;
  //add a bit of room at the top / bottom
  if (maxValue > 0) {
    maxValue += (difference * 0.2);
  }
  if (minValue < 0) {
    minValue -= (difference * 0.2);
  }
  if (minValue > 0) {
    minValue = 0;
  }
  if (maxValue < 0) {
    maxValue = 0;
  }
  if (minValue === 0 && maxValue === 0) {
    minValue = -10;
    maxValue = 10;
  }
  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <ResponsiveContainer height={200} width="100%">
        <BarChart
          margin={{ bottom: 10, right: 35 }}
          data={data}
          layout="vertical">
          <CartesianGrid vertical={true} horizontal={false} stroke={chartIceBlue} />
          <XAxis type="number" orientation="top" stroke={axisColour}
            tickFormatter={(tick) => `${(tick).toFixed(1)}%`}
            axisLine={false} tickLine={false} minTickGap={0.5}
            domain={[minValue, maxValue]} />
          <YAxis type="category" dataKey="name" width={100} axisLine={false} tickLine={false} stroke={axisColour} />
          <ReferenceLine x="0" stroke={axisColour} />
          <Bar barSize={30} dataKey="percentage" label={renderCustomizedLabel(maxValue, minValue)}>
            {data.map((entry, index: number) => (
              <Cell fill={colors[index % colors.length]} key={index} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  )
};

export default VerticalBars;
