import type { AssetClassRow } from "../AssetClass";
import type { SheetData } from "../../../../utils/xlsx/types";

export function transformRegionalBreakdown(headerCells: SheetData[], data: SheetData[]) {
  const columns = 2;
  const clone = [...data];
  const values: SheetData[][] = [];
  while (clone.length) {
    values.push(clone.splice(0, columns));
  }
  let rows: AssetClassRow[] = [];
  values.forEach((value: SheetData[]) => {
    const [stock, assetClass] = value;
    const rowData: AssetClassRow = {
      key: stock.h
    };
    const percentage = +assetClass.v;
    if (!isNaN(percentage) && percentage > 0) {
      rowData.percentage = percentage;
      rows.push(rowData);
    }
  });
  const headings: string[] = headerCells.map((value: SheetData) => value.w);
  rows.forEach(r => r.assetClass = `${((r.percentage || 0) * 100).toFixed(2)}%`)
  return { headings, rows };
}

export function transformForeignAssetsBreakdown(headerCells: SheetData[], data: SheetData[]) {
  const columns = 2;
  const clone = [...data];
  const values: SheetData[][] = [];
  while (clone.length) {
    values.push(clone.splice(0, columns));
  }
  let rows: AssetClassRow[] = [];
  values.forEach((value: SheetData[]) => {
    const [stock, assetClass] = value;
    const rowData: AssetClassRow = {
      key: stock.w
    };
    const percentage = +assetClass.v;
    if (!isNaN(percentage)) {
      rowData.percentage = percentage;
    }
    rows.push(rowData);
  });
  const headings: string[] = headerCells.map((value: SheetData) => value.w);
  rows.forEach(r => r.assetClass = `${((r.percentage || 0) * 100).toFixed(2)}%`)
  return { headings, rows };
}
export function transformPerformanceSummary(headerCells: SheetData[], data: SheetData[]) {
  const columns = 2;
  const clone = [...data];
  const values: SheetData[][] = [];
  while (clone.length) {
    values.push(clone.splice(0, columns));
  }
  let rows: AssetClassRow[] = [];
  values.forEach((value: SheetData[]) => {
    const [stock, assetClass] = value;
    const rowData: AssetClassRow = {
      key: stock.w
    };
    const percentage = +assetClass.v;
    if (!isNaN(percentage)) {
      rowData.percentage = percentage;
    }
    rows.push(rowData);
  });
  const headings: string[] = headerCells.map((value: SheetData) => value.w);
  rows.forEach(r => r.assetClass = `${((r.percentage || 0) * 100).toFixed(2)}%`)
  return { headings, rows };
}

export function transformPortfolioStatistics(headerCells: SheetData[], data: SheetData[]) {
  const columns = 2;
  const clone = [...data];
  const values: SheetData[][] = [];
  while (clone.length) {
    values.push(clone.splice(0, columns));
  }
  let rows: AssetClassRow[] = [];
  values.forEach((value: SheetData[]) => {
    const [stock, assetClass] = value;
    const rowData: AssetClassRow = {
      key: stock.w
    };
    const percentage = +assetClass.v;
    if (!isNaN(percentage) && percentage > 0) {
      rowData.percentage = percentage;
    } else {
      rowData.percentage = 0;
    }
    rowData.assetClass = assetClass.w;
    rows.push(rowData);
  });
  const headings: string[] = headerCells.map((value: SheetData) => value.w);
  return { headings, rows };
}