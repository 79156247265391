import { FactSheet } from "../../../../FactSheet";

export const getFundSizePrefix = (template: FactSheet): string => {
    switch (template) {
        case FactSheet.ss_next_gen_ucits:
        case FactSheet.ss_nfa_ucits:
            return 'US$'
        default:
            throw Error("Template undefined or not chosen for getFundSizePrefix");
    }
}