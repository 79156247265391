import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  > *:first-child {
    margin-top: 0;
  }
`;

const Heading = styled.h3`
`;

const Body = styled.p`
  white-space: pre-wrap;
  text-align: justify;
`;

export type InfoBlock = {
  header: string;
  body: string;
};

type ImportantInfoProps = {
  info: InfoBlock[];
};

const ImportantInformation = ({
  info
}: ImportantInfoProps) => {
  return (
    <Wrapper>
      {info &&
        info.map((item: InfoBlock, index: number) => (
          <React.Fragment key={index}>
            <Heading>{item.header}</Heading>
            <Body>
              {item.body}
            </Body>
          </React.Fragment>
        ))}
    </Wrapper>
  );
};

export default ImportantInformation;
