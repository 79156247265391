import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../../spinner';
import {FactSheet} from '../../../FactSheet';

export default ({ workbook, fundCommentary }) => {
    if (!workbook) {
        return (<Spinner />);
    }
    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col style={{ whiteSpace: 'pre-wrap' }} 
                    dangerouslySetInnerHTML={{ __html: fundCommentary }}>
                </Col>
            </Row>
        </React.Fragment>
    );
}