import React from "react";
import styled from "styled-components";
import { Heading } from '../../factsheets/Paragraph/Paragraph';
import { FactSheet } from '../../../FactSheet';
import { FlipCard, FlipCardInner, FlipCardPadder, FlipCardFront, FlipCardBack } from '../../../components';
import { veryDarkBlue, white, grey } from '../../../utils/colors';
import { getDailyPricingUrl } from '../../../utils/daily-pricing-helpers';

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Literature = styled.div`
    font-size: 14pt;
    margin: 5px;
    text-align: right;
`;

const LiteratureDate = styled.div`
    font-size: 11pt;
    text-align: right;
    margin: 5px;
    color: ${veryDarkBlue};
`;

const LiteratureDownload = styled.div`
    font-size: 11pt;
    padding: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: ${grey};
    color: ${white};
    text-align: right;
    &:hover {
        text-decoration: underline;
        color: ${white};
    }
`;


const downloadLiterature = (path) => {
    let fileName = path;
    fetch(`${process.env.GATSBY_GET_FILE_URL}&file=${path}`)
        .then(async result => {
            const data = await result.json();
            const linkSource = `data:application/pdf;base64,${data.data}`;
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            downloadLink.href = linkSource;
            downloadLink.target = '_self';
            downloadLink.download = fileName;
            downloadLink.click();
        })
}

const LiteratureGroup = ({ literatureGroup }) => {
    return (
        <React.Fragment>
            <Heading>{literatureGroup.name}</Heading>
            <Boxes>
                {literatureGroup.documents.map((document, index) =>
                    <FlipCard secondary={true} key={index} height="120px" width="300px">
                        <FlipCardInner secondary={true}>
                            <FlipCardPadder secondary={true}></FlipCardPadder>
                            <FlipCardFront secondary={true}>
                                <Literature onClick={() => downloadLiterature(document.path)}>
                                    {document.name}
                                </Literature>
                                {document.date
                                    ? (<LiteratureDate>
                                        {document.date}
                                    </LiteratureDate>)
                                    : <></>}
                            </FlipCardFront>
                            <FlipCardBack secondary={true}>
                                <LiteratureDownload onClick={() => downloadLiterature(document.path)}>Download</LiteratureDownload>
                            </FlipCardBack>
                        </FlipCardInner>
                    </FlipCard>
                )}
            </Boxes>
        </React.Fragment>
    );
};

export default ({ matchedTemplate, literatureSubtitle }) => {
    let dailyPricingUrl = getDailyPricingUrl(FactSheet[matchedTemplate.value]);
    return (
        <React.Fragment>
            {dailyPricingUrl ? (<>
                <Heading>Administration</Heading>
                <p>{literatureSubtitle} <a target="blank" href={dailyPricingUrl}>{dailyPricingUrl}</a></p>
            </>) : ''}
            {matchedTemplate.literatureGroups.map((lg, index) =>
                <LiteratureGroup key={index} literatureGroup={lg}></LiteratureGroup>
            )}
        </React.Fragment>
    );
}