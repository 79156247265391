import * as React from "react";
import styled from "styled-components";

import { red, paleGrey } from "../../../utils/colors";

const Table = styled.table`
  width: 100%;
  flex-grow: 1;
  border-collapse: collapse;
  margin-bottom: 1mm;
`;

const Body = styled.tbody`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.tr<{
  blue?: boolean;
  bold?: boolean;
  extraHeight?: number;
}>`
  background-color: ${(props) => (props.blue ? red : paleGrey)};
  color: ${(props) => (props.blue ? 'white' : 'inherit')};
  border-bottom: solid 1px ${(props) => (props.blue ? "white" : '#bfcfd8')};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  display: flex;
  min-height: ${(props) => (`${(7+ (props.extraHeight || 0))}mm`)};
  padding: 2px 5px;
  height: 100%;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;

  & th {
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  }
  & th,
  td {
    flex: 1;
    text-align: center;
    color: ${(props) => (props.blue ? 'white' : 'inherit')};
    &:nth-child(1) {
      text-align: left;
    }
  }
`;

export type Info = {
  key: string;
  value: string;
};


export type FundInformationProps = {
  info: Info[];
};
export default ({ info }: FundInformationProps) => (
  <Table>
    <Body>
      <Row blue={true} bold={true}>
        <th>Fund Information</th>
      </Row>
      {info && info.map((item: Info, index: number) => (
        <Row key={index} extraHeight={
          (((item.value.split('\n') || []).length) -1 ) * 3 }>
          <td>{item.key}</td>
          <td>{item.value}</td>
        </Row>
      ))}
    </Body>
  </Table>
);
