import { getDataRange } from "../../../../utils/xlsx";
import { getLastRowWithData } from "../../../../utils/xlsx/getLastRowWithData";

export const extractRegionalBreakdown = (sheet: any) => {
  const headerRange = {
    s: {
      c: 0,
      r: 0,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  let headers = getDataRange(headerRange, sheet);
  const range = {
    s: {
      c: 0, // A
      r: 1, // 2
    },
    e: {
      c: 1, // B
      r: getLastRowWithData(sheet, 0), 
    },
  };
  return {headers: headers, data: getDataRange(range, sheet)};
};

export const extractPortfolioStatistics = (sheet: any) => {
  const headerRange = {
    s: {
      c: 0,
      r: 0,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  let headers = getDataRange(headerRange, sheet);
  const range = {
    s: {
      c: 0, // A
      r: 1, // 2
    },
    e: {
      c: 1, // B
      r: getLastRowWithData(sheet, 0), 
    },
  };
  return {headers: headers, data: getDataRange(range, sheet)};
};

export const extractPerformanceSummary = (sheet: any) => {
  const headerRange = {
    s: {
      c: 0,
      r: 0,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  let headers = getDataRange(headerRange, sheet);
  const range = {
    s: {
      c: 0, // A
      r: 1, // 2
    },
    e: {
      c: 1, // B
      r: getLastRowWithData(sheet, 0), 
    },
  };
  return {headers: headers, data: getDataRange(range, sheet)};
};

export const extractForeignAssetsBreakdown = (sheet: any) => {
  const headerRange = {
    s: {
      c: 0,
      r: 0,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  let headers = getDataRange(headerRange, sheet);
  const range = {
    s: {
      c: 0, // A
      r: 1, // 2
    },
    e: {
      c: 1, // B
      r: 5
    },
  };
  return {headers: headers, data: getDataRange(range, sheet)};
};
