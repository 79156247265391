import { WorkSheet } from "xlsx/types";
import { XLSXCell, getCell } from "./getDataRange";

export const getLastRowWithData = (sheet: WorkSheet, column: number, startRow: number = 0): number => {
    let giveUpAt = 10000;
    for (let index = startRow; index < giveUpAt; index++) {
        let cellRef: XLSXCell = {
            c: column,
            r: index
        }
        let cell = getCell(cellRef, sheet);
        if (!cell || !cell.w){
            return index-1;
        }
    }
    throw new Error('Couldn\'t find the last cell with data');
}