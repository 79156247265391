import type { SheetData } from "../../../../utils/xlsx/types";
import { HeaderAndSheetData } from "../../../../utils/xlsx/getDataRange";
import { FactSheet } from "../../../../FactSheet";

export const transformCumulativePerf = (
  template: FactSheet, data: HeaderAndSheetData[]
): { headers: string[]; values: string[][], primaries: string[] } => {

  // Initialise Headers
  const headers = ["Cumulative Performance"];
  const { values, primaries }: { values: string[][]; primaries: string[]; } = transformData(template, data, headers);

  return { headers, values, primaries };
};

export const transformTwelveMonthPerf = (
  template: FactSheet, data: HeaderAndSheetData[]
): { headers: string[]; values: string[][], primaries: string[] } => {
  const headers = ["12 month Performance"];
  const { values, primaries }: { values: string[][]; primaries: string[]; } = transformData(template, data, headers);
  return { headers, values, primaries };
};
function transformData(template: FactSheet, data: HeaderAndSheetData[], headers: string[]) {
  const values: string[][] = [];
  const primaries: string[] = [];
  data.forEach((row, index) => {
    const columns = 2;
    const clone = [...row.data];
    const sheetData: SheetData[][] = [];
    // Split Array into Arrays of {columns} to represent the rows
    while (clone.length) {
      sheetData.push(clone.splice(0, columns));
    }
    // Transform the data to a useable format
    const rowValues: string[] = sheetData.map((item: SheetData[]) => {
      const [header, val] = item;
      if (index === 0) {
        //only need to add the headers once, assume all sheets have the same A column
        headers.push(header.w);
      }
      return val.w;
    });
    values.push(rowValues);
    primaries.push(row.header.w);
  });

  if (template === FactSheet.ss_next_gen_ucits || template === FactSheet.ss_nfa_ucits) {
    let newIndexes: any = {};
    primaries.forEach((p, index) => newIndexes[orderedClasses.indexOf(p)] = index);
    if (Object.keys(newIndexes).every(i => +i > -1)) {
      let orderedValues: string[][] = [];
      let orderedPrimaries: string[] = [];
      const newValues = Object.values(newIndexes) as number[];
      for (let i = 0; i < newValues.length; i++) {
        orderedValues.push(values[newValues[i]]);
        orderedPrimaries.push(primaries[newValues[i]]);
      }
      return { values: orderedValues, primaries: orderedPrimaries };
    }
  }
  return { values, primaries };
}

const orderedClasses = [
  'Class QAUSD',
  'Class QDUSD',
  'Class QAEUR Hedged',
  'Class QDEUR Hedged',
  'Class QDGBP Hedged'
]