import { FactSheet } from "../../../../FactSheet";
import { Info } from "..";

/*
 * Decide which hardcoded data will be returned base on passed in Template
 */
export const getStaticData = (template: FactSheet): Info[] => {
  switch (template) {
    case FactSheet.ss_nfa_ucits:
      return ss_nfa_ucits;
    case FactSheet.ss_next_gen_ucits:
      return ss_next_gen_ucits;
    default:
      throw Error("Template for getting static fund info not defined");
  }
};

const ss_nfa_ucits: Info[] = [
  {
    key: "Fund Launch Date",
    value: "9 December 2016",
  },
  {
    key: "Pricing Frequency",
    value: "Daily",
  },
  {
    key: "Domicile",
    value: "Luxembourg",
  },
  {
    key: "Exit Charge",
    value: "None",
  },
];

const ss_next_gen_ucits: Info[] = [
  {
    key: "Fund Launch Date",
    value: "19 December 2016",
  },
  {
    key: "Pricing Frequency",
    value: "Daily",
  },
  {
    key: "Domicile",
    value: "Luxembourg",
  },
  {
    key: "Exit Charge",
    value: "None",
  },
];