import { FactSheet } from "../../../../FactSheet";

export const decideData = (template: FactSheet): any => {
  switch (template) {
    case FactSheet.ss_next_gen_ucits:
      return ss_next_gen_ucits;
    case FactSheet.ss_nfa_ucits:
      return ss_nfa_ucits;
    default:
      throw Error('Template not supported for share class');
  }
};

const ss_next_gen_ucits = {
  headers: [
    "Share Class",
    "QA USD",
    "QD USD",
    "QA EUR Hedged",
    "QD EUR Hedged",
    "QA GBP Hedged",
    "QD GBP Hedged",
    "RD EUR Hedged"
  ],
  primarys: [
    "NAV per Share",
    "Minimum Initial Investment",
    "Minimum Additional Investment",
    "Entry Charge",    
    "Ongoing Charge (as at 31/12/2020)",
    "Launch Date",
    "ISIN",
    "Bloomberg Ticker",
  ],
  values: [
    { sheetIndex: 0, cellRef: [{ c: 1, r: 1 }, { c: 1, r: 2 }, { c: 1, r: 3 }, { c: 1, r: 4 }, { c: 1, r: 5 }, { c: 1, r: 6 }, { c: 1, r: 7 }] },
    { cellRef: ["€100,000", "$100,000", "£100,000", "€100,000", "$100,000", "£100,000", "No minimum"] },
    { cellRef: ["None", "None", "None", "None", "None", "None", "None"] },
    { cellRef: ["Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%"] },
    { cellRef: ["1.02%", "1.04%", "0.97%", "0.94%", "N/A", "0.97%", "N/A"] },
    { cellRef: ["02/06/2017", "24/02/2020", "19/12/2016", "23/12/2016", "Inactive available on demand", "19/12/2016", "Inactive available on demand"] },
    { cellRef: ["LU1483929433", "LU1483929193", "LU1483929516", "LU1483929276", "tbc", "LU1483929359", "LU1483929789"] },
    { cellRef: ["SNGGQAU LX", "SNGGQDU LX", "SNGGQAE LX", "SSNGQDE LX", "tbc", "SSNGQDG LX", "tbc"] },
  ],
};

const ss_nfa_ucits = {
  headers: [
    "Share Class",
    "QA USD",
    "QD USD",
    "QA EUR Hedged",
    "QD EUR Hedged",
    "QA GBP Hedged",
    "QD GBP Hedged",
    "RD EUR Hedged"
  ],
  primarys: [
    "NAV per Share",
    "Minimum Initial Investment",
    "Minimum Additional Investment",
    "Entry Charge",    
    "Ongoing Charge (as at 31/12/2020)",
    "Launch Date",
    "ISIN",
    "Bloomberg Ticker",
  ],
  values: [
    { sheetIndex: 0, cellRef: [{ c: 1, r: 1 }, { c: 1, r: 2 }, { c: 1, r: 3 }, { c: 1, r: 4 }, { c: 1, r: 5 }, { c: 1, r: 6 }, { c: 1, r: 7 }] },
    { cellRef: ["€100,000", "$100,000", "£100,000", "€100,000", "$100,000", "£100,000", "No minimum"] },
    { cellRef: ["None", "None", "None", "None", "None", "None", "None"] },
    { cellRef: ["Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%", "Up to 3%"] },    
    { cellRef: ["1.05%", "1.14%", "0.97%", "0.94%", "1.68%", "1.03%", "N/A"] },
    { cellRef: ["13/12/2016", "09/12/2016", "30/11/2016", "01/11/2020", "Inactive available on demand", "30/11/2016", "Inactive available on demand"] },
    { cellRef: ["LU1483930100", "LU1483929862", "LU1483930282", "LU1483929946", "LU1483930365", "LU1483930019", "LU1483930449"] },
    { cellRef: ["SNFAQAU LX", "SNFAQDU LX", "SNFAQAE LX", "SNFAQDE LX", "tbc", "SNFAQDG LX", "tbc"] },
  ],
};