import { WorkSheet } from "xlsx/types";
import { SheetData, XLSXRange } from "../../../../utils/xlsx/types";
import { getDataRange } from "../../../../utils/xlsx";
import { getLastRowWithData } from "../../../../utils/xlsx/getLastRowWithData";

export const extractEntityBreakdown = (sheet: WorkSheet): SheetData[][] => {
  const firstRow = 1;//2
  const lastRow = getLastRowWithData(sheet, 0);//6
  const labelColumn = 0;//A
  const valueColumn = 1;//B
  const dateRange: XLSXRange = {
    s: {
      c: labelColumn,
      r: firstRow,
    },
    e: {
      c: labelColumn,
      r: lastRow,
    },
  };
  const valueRange: XLSXRange = {
    s: {
      c: valueColumn,
      r: firstRow,
    },
    e: {
      c: valueColumn,
      r: lastRow
    },
  };
  return [getDataRange(dateRange, sheet), getDataRange(valueRange, sheet)];
}