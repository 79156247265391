import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 4px 0 0 0;
`;

const Heading = styled.h3`
  margin: 0 0 5px 0;
`;

const Body = styled.div<{ column: boolean; justify?: boolean }>`
  margin: 0;
  white-space: pre-wrap;
  text-align: ${(props) => (props.justify ? "left" : "justify")};
  text-justify: inter-word;
  columns: ${(props) => (props.column ? 2 : 1)};
`;

const Break = styled.hr`
  height: 2px;
  background: black;
  border: none;
  margin-bottom: 0;
  box-sizing: border-box;
  width: 100%;
`;

type Props = {
  heading: string;
  body: string;
} & typeof defaultProps;
const defaultProps = {
  backgroundColor: "#d8e2e8",
  bottomBorder: false,
  column: false,
};

const TextBox = ({
  heading,
  body,
  bottomBorder,
  backgroundColor,
  column,
}: Props) => (
  <Wrapper color={backgroundColor}>
    <Heading>{heading}</Heading>
    <Body column={column} justify={backgroundColor === "white" ? true : true}>
      {body}
    </Body>
    {bottomBorder && backgroundColor === "white" && <Break />}
  </Wrapper>
);

TextBox.defaultProps = defaultProps;

export default TextBox;
