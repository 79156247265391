import React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { DefaultLayout } from "../layouts";
import { Background, Header, FundSection } from "../components";
import { Match } from "@reach/router";
import FundTabs from '../components/funds/FundTabs';
import FundAwards from '../components/funds/FundAwards';
import { FactSheet } from "../FactSheet";

const FundsNav = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  border: 1px solid ;
  border: solid 1px rgb(89,89,89, 0.3);
  margin-right: 10px;
`;
const FundsNavGroup = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    padding: 5px;
    :first-child {
      color: white;
      background-color: rgb(89,89,89, 0.75);
      font-size: 11pt;
    }
  }

  > span {
    color: white;
    background-color: rgb(89,89,89, 0.5);
    font-size: 10pt;
    cursor: pointer;
    &.active {
      color: red;
      background-color: white;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ({ pageContext, data }) => {
  const factsheet = pageContext.slug;
  const groups = data.funds.groups;
  const tempVals = groups.map(g => g.templates);
  const tempNames = [].concat(...tempVals);
  const matchedTemplate = tempNames.find(f => f.value === factsheet);

  function templateClicked(template) {
    navigate(`/funds/${template}`);
  }

  return (<DefaultLayout
    title={matchedTemplate.name}
  >
    <Background image={data.logo.publicURL}>
      <Header color="white">
        {matchedTemplate.name}
      </Header>
    </Background>
    <FundSection>
      <div className="d-none d-md-flex flex-column" style={{minWidth: '200px'}}>
        <FundsNav>
          <FundsNavGroup>
            <div>Funds</div>
            {groups.map((group, index) => {
              return (<React.Fragment key={index}>
                {group.templates.map((template, tIndex) => {
                  return (
                    <React.Fragment key={tIndex}>
                      <Match path={`/funds/${template.value}`}>
                        {props => props.match ?
                          <span className="active">
                            {template.name}
                          </span> :
                          <span onClick={() => templateClicked(template.value)}>
                            {template.name}
                          </span>}
                      </Match>
                    </React.Fragment>
                  )
                })}
              </React.Fragment>)
            })}
          </FundsNavGroup>
        </FundsNav>
        <div>
          <FundAwards template={FactSheet[matchedTemplate.value]} />
        </div>
      </div>
      <FundTabs template={matchedTemplate}></FundTabs>
    </FundSection>
  </DefaultLayout>
  );
}

export const query = graphql`
  {
    funds: fundsJson {
      literatureSubtitle
      groups {
        templates {
          literatureGroups {
            documents {
              name
              date
              path
              useFactsheetDate
            }
            name
          }
          cityWire
          value
          name
        }
        name
      }
    }
    logo: file(name: { eq: "our-funds" }) {
      publicURL
    }
    cityWireAward: file(name: { eq: "CityWireAA" }) {
      publicURL
    }
  }
`;
