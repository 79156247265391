import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FromWorkBook as FundInfo } from '../../factsheets/FundInformation/FundInformation.stories';
import { FromWorkBook as Shares } from '../../factsheets/Shares/Shares.stories';
import Spinner from '../../spinner';



export default ({ workbook, template }) => {
    if (!workbook){
        return (<Spinner />);
    }
    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {FundInfo(workbook, template)}
                </Col>
            </Row>
            <Row>
                <Col style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {Shares(workbook, template)}
                </Col>
            </Row>
        </React.Fragment>
    );
}