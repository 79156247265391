import { WorkSheet } from "xlsx/types";
import { Info } from "..";
import { getLastRowWithData } from "../../../../utils/xlsx/getLastRowWithData";
import { XLSXRange } from "../../../../utils/xlsx/types";
import { getDataRange } from "../../../../utils/xlsx";
import { getFundSizePrefix } from "./getFundSizePrefix";
import { FactSheet } from "../../../../FactSheet";
import { formatMoney } from "./formatMoney";

export const combineWithExcelData = (sheet: WorkSheet, oldInfo: Info[], template: FactSheet): Info[] => {
  let info: Info[] = [];
  info = info.concat(oldInfo);  
  const minRow = 1;
  const maxRow = getLastRowWithData(sheet, 0);
  const nameRange: XLSXRange = {
    s: { c: 0, r: minRow },
    e: { c: 0, r: maxRow }
  }
  const valueRange: XLSXRange = {
    s: { c: 1, r: minRow },
    e: { c: 1, r: maxRow }
  }

  const nameCells = getDataRange(nameRange, sheet);
  const valueCells = getDataRange(valueRange, sheet);
  const excelValues: Info[] = [];
  for (let index = 0; index < nameCells.length; index++) {
    let nameCell = nameCells[index];
    let valueCell = valueCells[index];
    

    let value = valueCell.w;
    if (nameCell.w === 'Fund Size' && !isNaN(+valueCell.v)){
      value = getFundSizePrefix(template)+ formatMoney(+valueCell.v);
    }
    excelValues.push({ key: nameCell.w, value: value })
  }

  //Put the excel data from the second row
  let first = info[0];
  let middle = excelValues;
  let end = info.splice(1, info.length - 1);
  return [first, ...middle, ...end];
};
