import * as React from "react";
import styled from "styled-components";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, LegendProps, LegendPayload } from "recharts";

import { paleGrey, GetChartColours, GetLabelledChartColors } from "../../../utils/colors";

const LegendList = styled.div`
  display: flex;
  flex-direction: column;
`;
const LegendListItem = styled.div`
  display: flex;
  margin-bottom: 1mm;
  > * {
    font-size: 7.5pt;
  }
`;
const LegendBlock = styled.span<{ color: string }>`
  height: 4mm;
  width: 4mm;
  max-width: 4mm;
  min-width: 4mm;
  margin-right: 1mm;
  background-color: ${props => props.color};
  display: block;
`;

const Wrapper = styled.div`
  background-color: ${paleGrey};
  flex: 1;
  flex-basis: calc(49% - 10px);
  .recharts-legend-item {
    margin: 0 !important;
  }
  h3 {
      background: #dd052d;
      color: white;
      font-size: 1em;
      text-align: center;
      padding: 6px;
  }
`;

const Heading = styled.h3`
  margin: 0;
  padding: 15px 0 5px 0;
  text-align: center;
  font-size: 11pt;
  font-weight: 500;
`;

export const MultiGraphWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    border: solid white 5px;
  }
`;

export type PieChartData = {
  name: string;
  value: number;
  showPercentage: boolean;
  colour?: string;
};

type CustomLabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
};

const MIN_PERCENT = 0.05;

/**
 * Support conditionally showing radius if data is small enough
 */
const renderCustomizedLabelLine = (data: PieChartData[]) => ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
}: CustomLabelProps) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  let startX = 0;
  let startY = 0;
  let endX = 0;
  let endY = 0;
  if (!data[index].showPercentage) {
    startX = cx + radius * Math.cos(-midAngle * RADIAN);
    startY = cy + radius * Math.sin(-midAngle * RADIAN);
    endX = startX + radius * Math.cos(-midAngle * RADIAN) * 0.5;
    endY = startY + radius * Math.sin(-midAngle * RADIAN) * 0.5;
  }
  return (
    <line
      x1={startX}
      x2={endX}
      y1={startY}
      y2={endY}
      stroke={data[index].colour}
    ></line>
  );
};
const renderCustomizedLabel = (data: PieChartData[]) => ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: CustomLabelProps) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.15;
  let x = cx + radius * Math.cos(-midAngle * RADIAN);
  let y = cy + radius * Math.sin(-midAngle * RADIAN);
  let stroke = "white";
  let label = `${(percent * 100).toFixed(1)}%`;
  const datum = data[index];
  const outsideChart = !datum.showPercentage;
  if (outsideChart) {
    x += radius * Math.cos(-midAngle * RADIAN) * 0.8;
    y += radius * Math.sin(-midAngle * RADIAN) * 0.8;
    stroke = "black";
    if (!data[index].showPercentage) {
      label = data[index].name;
      //180 is LHS
      let words = label.split(' ');
      const angleFromRight = Math.abs(midAngle - 180);
      let xAdder = 0;
      let maxWord = [...words].sort((a, b) => b.length - a.length)[0];
      if (angleFromRight <= 30 && maxWord.length > 4) {
        xAdder = -(maxWord.length / 2);
      }

      if (angleFromRight <= 45) {
        let yAdder = -20 + (8 + words.length * 1.2)
        return (
          <text
            x={x}
            dx={xAdder}
            y={y + yAdder}
            fill={stroke}
            textAnchor="middle"
          >
            {words.map((w, i) => (
              <tspan key={i}
                x={x - 7}
                style={{ fontSize: '7.5pt' }}
                dy="1.1em">
                {w}
              </tspan>
            ))}
          </text>
        );
      }
    }
  } else {
    if (percent < MIN_PERCENT) {
      return null;
    }
    x += radius * Math.cos(-midAngle * RADIAN) * 0.2;
    y += radius * Math.sin(-midAngle * RADIAN) * 0.2;
  }

  return (
    <text
      x={x}
      y={y}
      fill={stroke}
      style={{ fontSize: '7.5pt' }}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {label}
    </text>
  );
};

export type Props = {
  heading?: string;
  data: PieChartData[];
};

const renderLegend = (props: LegendProps) => {
  const { payload } = props;

  return (
    <LegendList>
      {
        (payload || []).map((entry: LegendPayload, index: number) => (
          <LegendListItem key={`item-${index}`}>
            <LegendBlock color={entry.color || ''}></LegendBlock>
            <span style={{ whiteSpace: 'pre' }}>{entry.value} - </span>
            <span style={{ flex: '1' }}>{entry.id}</span>
          </LegendListItem>
        ))
      }
    </LegendList>
  );
}

const Donut = ({ heading, data }: Props) => {
  //exclude zeros
  data = data
    .filter((d) => d.value > 0)
    .reverse();

  const colorsLength = data.filter(d => d.showPercentage).length;
  let colors = GetChartColours(colorsLength);
  let labelColors = GetLabelledChartColors();

  [...data]
    .reverse()
    .filter((d) => d.showPercentage)
    .forEach((d, index) => {
      (d as any)["colour"] = colors[index % colors.length];
    });

  [...data]
    .reverse()
    .filter((d) => !d.showPercentage)
    .forEach((d, index) => {
      (d as any)["colour"] = labelColors[index % labelColors.length];
    });

  let height = 200;
  const cloneData = [...data];
  const legendPayload = cloneData.filter(d => d.showPercentage);
  if (legendPayload.length > 8) {
    height = (legendPayload.length - 8) * 30 + 200;
  }
  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <ResponsiveContainer height={height} width="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            innerRadius="50%"
            paddingAngle={4}
            startAngle={90}
            endAngle={480}
            blendStroke
            // @ts-ignore
            label={renderCustomizedLabel(data)}
            labelLine={renderCustomizedLabelLine(data)}
          >
            {data.map((entry, index: number) => (
              <Cell fill={entry.colour} key={index} >
              </Cell>
            ))}
          </Pie>
          <Legend
            align="right"
            layout="vertical"
            width={130}
            verticalAlign="middle"
            content={renderLegend}
            payload={legendPayload
              .reverse()
              .map((d) => ({
                value: `${d.value.toFixed(1)}%`,
                type: "square",
                id: d.name,
                color: d.colour,
              }))}
          />
        </PieChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default Donut;
