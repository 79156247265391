import React from "react";
import { FactSheet } from "../../FactSheet";
import { Tabs, Tab } from "react-bootstrap";
import * as workerLoader from './workBook.worker.js';
import * as moment from 'moment';
import styled from 'styled-components';
import FundInformation from './Tabs/FundInformation';
import Exposures from './Tabs/Exposures';
import Performance from './Tabs/Performance';
import Commentary from './Tabs/Commentary';
import KeyInfo from './Tabs/KeyInfo';
import Literature from './Tabs/Literature';

export const SourceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TabsContainer = styled.div`
    width: 100%;
`;

const parseDate = (name) => {
    let regex = new RegExp('\\d{4}_\\d{2}_\\d{2}');
    let matches = name.match(regex);
    if (matches && matches.length > 0) {
        let match = matches[0];
        let mom = moment(match, 'YYYY_MM_DD');
        return mom;
    }
    return null;
}
const source = (mom, template) => (
    <SourceWrapper>
        <i className="mt-2">Monthly data as at: {mom.format('DD/MM/yyyy')}.
            All information also available to download <a target="blank" href={`https://garrawaystorage.blob.core.windows.net/ss-files/Factsheets/${template}.pdf`}>here</a></i>
    </SourceWrapper>
);

export default class FundTabs extends React.Component {
    constructor(props) {
        super(props);

        this.selectLiterature = this.selectLiterature.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            workbook: null,
            template: FactSheet[props.template.value],
            matchedTemplate: props.template,
            fundCommentary: null,
            factsheetDate: null,
            selectedTab: 'summary'
        }
    }

    selectLiterature() {
        this.handleSelect('literature');
    }

    handleSelect(key) {
        this.setState({ selectedTab: key });
    }

    componentDidMount() {
        fetch(`${process.env.GATSBY_FACT_SHEET_URL}&template=${FactSheet[this.state.template]}`)
            .then(async res => {
                var result = await res.json();
                var base64Str = result.data;
                this.setState({
                    factsheetDate: parseDate(result.name)
                });

                let worker = await workerLoader();
                let workbook = await worker.createWorkBook(base64Str);
                this.setState({
                    workbook: workbook
                });
            });

        fetch(`${process.env.GATSBY_GET_FILE_URL}&file=Fund Commentaries/${FactSheet[this.state.template]}.txt`)
            .then(async res => {
                const result = await res.json();
                const fundCommentary = result.data;

                const decodedFundCommentary = decodeURIComponent(atob(fundCommentary).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                this.setState({
                    fundCommentary: decodedFundCommentary
                });
            });
    }

    render() {
        const { workbook, template, matchedTemplate, fundCommentary } = this.state;
        return (
            <TabsContainer>
                <Tabs activeKey={this.state.selectedTab} onSelect={this.handleSelect} >
                    <Tab eventKey="summary" title="Summary">
                        <FundInformation template={template}
                            matchedTemplate={matchedTemplate}
                            selectLiterature={this.selectLiterature}
                            factsheetDate={this.state.factsheetDate}
                            cityWire={this.props.cityWireAward}></FundInformation>
                    </Tab>
                    <Tab eventKey="portfolio" title="Portfolio">
                        <Exposures template={template} workbook={workbook}></Exposures>
                        {this.state.factsheetDate ? source(this.state.factsheetDate, matchedTemplate.value) : ''}
                    </Tab>
                    <Tab eventKey="performance" title="Performance">
                        <Performance template={template} workbook={workbook}></Performance>
                        {this.state.factsheetDate ? source(this.state.factsheetDate, matchedTemplate.value) : ''}
                    </Tab>
                    <Tab eventKey="commentary" title="Commentary">
                        <Commentary workbook={workbook} fundCommentary={fundCommentary}></Commentary>
                        {this.state.factsheetDate ? source(this.state.factsheetDate, matchedTemplate.value) : ''}
                    </Tab>
                    <Tab eventKey="information" title="Information">
                        <KeyInfo template={template} workbook={workbook}></KeyInfo>
                        {this.state.factsheetDate ? source(this.state.factsheetDate, matchedTemplate.value) : ''}
                    </Tab>
                    <Tab eventKey="literature" title="Literature">
                        <Literature matchedTemplate={matchedTemplate}
                            literatureSubtitle={this.props.literatureSubtitle}
                            factsheetDate={this.state.factsheetDate}></Literature>
                    </Tab>
                </Tabs>
            </TabsContainer>
        );
    }
}