import React from "react";
import { FactSheet } from "../../../FactSheet";
import { Row, Col } from 'react-bootstrap';
import { FromWorkBook as RegionalBreakdownAssetClass } from "../../../components/factsheets/AssetClass/stories/RegionalBreakdown.stories";
import { FromWorkBook as PortfolioStatistics } from "../../../components/factsheets/AssetClass/stories/PortfolioStatistics.stories";
import { FromWorkBook as ForeignAssetsBreakdown } from "../../../components/factsheets/AssetClass/stories/ForeignAssetsBreakdown.stories";
import { FromWorkBook as EntityBreakdown } from "../../../components/factsheets/Donut/EntityBreakdown.stories";
import { FromWorkBook as CreditRatingBreakdown } from "../../../components/factsheets/Bar/CreditRatingBreakdown";
import Spinner from '../../spinner';
import styled from "styled-components";

const Standard = (template, workbook) => {
    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col className="pr-md-1 col-12 col-md-4 mb-2 mb-md-none">
                    {RegionalBreakdownAssetClass(workbook, template)}
                </Col>
                <Col className="px-md-1 col-12 col-md-4 mb-2 mb-md-none">
                    {PortfolioStatistics(workbook, template)}
                </Col>
                <Col className="pl-md-1 col-12 col-md-4">
                    {ForeignAssetsBreakdown(workbook, template)}
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1 col-12 col-md-6 mb-2">
                    {EntityBreakdown(workbook, template)}
                </Col>
                <Col className="pl-md-1 col-12 col-md-6 mb-2">
                    {CreditRatingBreakdown(workbook, template)}
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ({ template, workbook }) => {
    if (!workbook) {
        return (<Spinner />);
    }
    switch (template) {
        case FactSheet.ss_next_gen_ucits:
        case FactSheet.ss_nfa_ucits:
            return Standard(template, workbook);
        default:
            return <></>;
    }
}