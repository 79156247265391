import * as React from "react";
import { CumulativePerformance } from "../";
import { createSheet } from "../../../../utils/xlsx";
import {
  decideTwelveMonthPerfSheets,
  extractTwelveMonthPerf,
  transformTwelveMonthPerf,
} from "../helpers";
import { WorkBook } from "xlsx/types";
import { FactSheet } from "../../../../FactSheet";

export const FromWorkBook = (template: FactSheet, workbook: WorkBook) => {
  const sheetIndexes = decideTwelveMonthPerfSheets();
  const sheets = sheetIndexes.map(sheetIndex => createSheet(workbook, sheetIndex));
  const sheetData = extractTwelveMonthPerf(sheets);
  const { headers, values, primaries } = transformTwelveMonthPerf(template, sheetData);

  return (
    <CumulativePerformance
      headers={headers}
      values={values}
      primaries={primaries}
    />
  );
}