import React from 'react';
import { FromWorkBook as CumulativePerformance } from '../../factsheets/CumulativePerformance/stories/CumulativePerformance.stories';
import { FromWorkBook as TwelveMonthPerformance } from '../../factsheets/CumulativePerformance/stories/TwelveMonthPerformance.stories';
import { FromWorkBook as PerformanceSummary } from '../../factsheets/AssetClass/stories/PerformanceSummary.stories';
import { Row, Col } from 'react-bootstrap';
import { FactSheet } from '../../../FactSheet';
import Spinner from '../../spinner';

const Standard = (template, workbook) => {
    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {CumulativePerformance(template, workbook)}
                </Col>
            </Row>
            <Row className="mb-2">
                <Col style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {TwelveMonthPerformance(template, workbook)}
                </Col>
            </Row>
            <Row className="mb-2">
                <Col style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {PerformanceSummary(workbook, template)}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ({ template, workbook }) => {
    if (!workbook) {
        return (<Spinner />);
    }
    switch (template) {
        case FactSheet.ss_next_gen_ucits:
        case FactSheet.ss_nfa_ucits:
            return Standard(template, workbook);
        default:
            return <div></div>;
    }
}