import { getDataRange } from "../../../../utils/xlsx";
import type {  XLSXRange } from "../../../../utils/xlsx/types";
import { WorkSheet } from "xlsx/types";
import { getCell, HeaderAndSheetData } from "../../../../utils/xlsx/getDataRange";

export const extractCumulativePerf = (sheets: WorkSheet[]): HeaderAndSheetData[] => {
  return extractData(sheets);
};

export const extractTwelveMonthPerf = (sheets: WorkSheet[]): HeaderAndSheetData[] => {
  return extractData(sheets);
};
function extractData(sheets: WorkSheet[]): HeaderAndSheetData[] {
  return sheets.map(sheet => {
    const range: XLSXRange = {
      s: {
        c: 0,
        r: 1,
      },
      e: {
        c: 1,
        r: getMaxRow(sheet),
      },
    };
    const header = getCell({ c: 0, r: 0 }, sheet);
    return { data: getDataRange(range, sheet), header: header };
  });
}

function getMaxRow(sheet: WorkSheet): number {
  let givingUpAt = 100;

  //don't want a while loop, so do a safe number of rows
  for (let i = 0; i < givingUpAt; i++) {
    let cell = getCell({ c: 1, r: i }, sheet);
    if (!cell || !cell.v) {
      //return last row we had data for
      return i-1;
    }
  }
  throw Error('Couldn\'t find last row of data for Cumulative Performance');
}

