export const decideRegionalBreakdownSheet = (): number => {
  return 3;
};
export const decidePortfolioStatisticsSheet = (): number => {
  return 8;
};
export const decideForeignAssetsBreakdownSheet = (): number => {
  return 6;
};
export const decidePerformanceSummarySheet = (): number => {
  return 7;
};