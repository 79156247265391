import * as React from "react";
import AssetClass, { GridArea } from "../AssetClass";
import {
  decidePerformanceSummarySheet,
  extractPerformanceSummary,
  transformPerformanceSummary,
} from "../helpers";
import { createSheet } from "../../../../utils/xlsx";
import { WorkBook } from "xlsx/types";

export function FromWorkBook(workbook: WorkBook, gridArea?: GridArea) {
  const assetClassData = DataFromWorkBook(workbook);
  return <AssetClass columns={1} {...assetClassData} gridArea={gridArea} />;
}

export function DataFromWorkBook(workbook: WorkBook) {
  const sheetIndex = decidePerformanceSummarySheet();
  const sheet = createSheet(workbook, sheetIndex);
  const {headers,data} = extractPerformanceSummary(sheet);
  const assetClassData = transformPerformanceSummary(headers, data);
  return assetClassData;
}
