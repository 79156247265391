import * as React from "react";
import { FactSheet } from "../../../FactSheet";
import { WorkBook } from "xlsx/types";
import { getCreditBreakdownSheet } from "./helpers/getSheet";
import { extractCreditBreakdown } from "./helpers/extractData";
import { transformSheetData } from "./helpers/transformData";
import VerticalBars from "./VerticalBars";

export const FromWorkBook = (workbook: WorkBook, template: FactSheet) => {
  const sheet = getCreditBreakdownSheet(workbook);
  const sheetData = extractCreditBreakdown(sheet);
  const data = transformSheetData(sheetData);

  return (
    <VerticalBars
      heading="Credit Rating Breakdown (% NAV)"
      data={data}/>
  );
}