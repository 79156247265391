import { utils } from "xlsx";

export type SheetData = {
  h: string;
  r: string;
  t: string;
  v: string | number;
  w: string;
};
export type HeaderAndSheetData = {
  header: SheetData;
  data: SheetData[]
};

export type XLSXCell = {
  c: number; // Column
  r: number; // Row
}

export type XLSXRange = {
  s: XLSXCell;
  e: XLSXCell;
};

export const getDataRange = (range: XLSXRange, sheet: any): SheetData[] => {
  let dataRange = [];
  /* Iterate through each element in the structure */
  for (var R = range.s.r; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var cell_address = { c: C, r: R };
      var data = utils.encode_cell(cell_address);
      dataRange.push(sheet[data]);
    }
  }
  return dataRange;
};

export const getCell = (cellRef: XLSXCell, sheet: any): SheetData => {
  var cell_address = { c: cellRef.c, r: cellRef.r };
  var data = utils.encode_cell(cell_address);
  return sheet[data];
};
