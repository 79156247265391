import * as React from "react";
import styled from "styled-components";
import {paleGrey} from '../../../utils/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  > div:not(:last-child) {
    border-bottom: 1px solid black;
  }
  > div:first-child h3 {
    margin-top:0;
  }
  > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const NormalParagraph = styled.div`
  width: 100%;
`;

export const BigParagraph = styled.p`
  width: 100%;
  column-count: 2;
  column-fill: auto;
  flex-grow: 1;
  text-align: justify;
  margin-top: 0;
  white-space: pre-wrap;
  height: 100%;

  ul {
    margin: 0;
  }
`;

export const Heading = styled.h3`
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(89,89,89, 0.5);
  display: flex;
  align-items: center;
  font-size: 11pt;
  font-weight: 500;
`;

const Body = styled.p`
  white-space: pre-wrap;
  text-align: justify;
  margin-top: 0;
  flex: 1;
`;

export type ParagraphModel = {
  header: string;
  body: string[];
  splitColumns?: boolean;
  cityWire?: boolean;
};

type ParagraphProps = {
  paragraph: ParagraphModel;
};

const DecideParagraph = (
  paragraph: ParagraphModel
) => {
  return !paragraph.splitColumns ? (
    <NormalParagraph>
      {paragraph.body.map((text: string, index: number) => {
        return (
          <Body key={index}>
            {text}
          </Body>
        );
      })}
    </NormalParagraph>
  ) : (
    <BigParagraph 
    dangerouslySetInnerHTML={{
      __html: paragraph.body[0]
    }}>
    </BigParagraph>
  );
};

const Paragraph = ({ paragraph }: ParagraphProps) => {
  return (
    <>
      <Heading>
        {paragraph.header}
      </Heading>
      {DecideParagraph(paragraph)}
    </>
  );
};

export default Paragraph;
