// @ts-nocheck
import * as React from "react";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  display: flex;
  & tbody {
    width: 100%;
  }
  & tbody tr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & th {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
  }
`;

const TopRow = styled.tr`
  background-color: #d8e2e8;
  border-bottom: solid 1px black;
`;

const BottomRow = styled.tr`
  border-bottom: solid 1px #bfcfd8;
`;

const NormalTableHead = styled.th`
  font-weight: normal;
`;

export type TwelveMonthPerformanceData = {
  "Class GBP Acc.": string;
  "Cumulative Performance": number;
};

export default ({ data }: { data: TwelveMonthPerformanceData[] }) => {
  return (
    <Table>
      <tbody>
        <TopRow>
          <th>12 month Performance</th>
          {data.map((item: TwelveMonthPerformanceData, index: number) => {
            const key = Object.keys(data[0])[0];
            return <th key={index}>{item[key]}</th>;
          })}
        </TopRow>
        <BottomRow>
          <th>{Object.keys(data[0])[0]}</th>
          {data.map((item: TwelveMonthPerformanceData, index: number) => {
            const key = Object.keys(data[0])[1];
            return <NormalTableHead key={index}>{item[key]}</NormalTableHead>;
          })}
        </BottomRow>
      </tbody>
    </Table>
  );
};
